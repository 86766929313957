import React, { useState } from 'react';
import './App.css';

function App() {
  const [savings, setSavings] = useState(0);
  const [recommendations, setRecommendations] = useState([]);

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value);
    setSavings(value);
    calculateRecommendations(value);
  };

  const calculateRecommendations = (totalSavings) => {
    let recommendations = [];
    let remaining = totalSavings;

    // First $150,000 should go into UOB account
    if (remaining > 0) {
      const uobAmount = Math.min(remaining, 150000);
      recommendations.push({
        name: 'UOB Account',
        amount: uobAmount,
        interestRate: 'Up to 4%',
      });
      remaining -= uobAmount;
    }

    // Next $60,000 should go into GXS account
    if (remaining > 0) {
      const gxsAmount = Math.min(remaining, 60000);
      recommendations.push({
        name: 'GXS Account',
        amount: gxsAmount,
        interestRate: '3.28%',
      });
      remaining -= gxsAmount;
    }

    // Remaining amount should be invested in the stock market
    if (remaining > 0) {
      recommendations.push({
        name: 'Stock Market Investment',
        amount: remaining,
        interestRate: 'Range of possible returns (e.g., 5-10%)',
      });
    }

    setRecommendations(recommendations);
  };

  return (
    <div className="App">
      <div className="container">
        <div className="panel left-panel">
          <h2>Enter Your Savings</h2>
          <div className="input-wrapper">
            <span className="currency-symbol">$</span>
            <input
              type="number"
              value={savings}
              onChange={handleInputChange}
              placeholder="Enter amount in SGD"
            />
          </div>
        </div>
        <div className="panel right-panel">
          <h2>Suggested Savings Allocation</h2>
          {recommendations.length > 0 ? (
            <div className="recommendations">
              {recommendations.map((rec, index) => (
                <div className="recommendation-card" key={index}>
                  <h3>{rec.name}</h3>
                  <p>Amount: <strong>${rec.amount.toLocaleString()}</strong></p>
                  <p>Interest Rate: <strong>{rec.interestRate}</strong></p>
                </div>
              ))}
            </div>
          ) : (
            <p>Enter your savings amount to see recommendations.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
